import React, { useCallback, useEffect, useReducer, useMemo } from 'react'
import { Button, Col, Form, message, Row, Select, Typography, Divider, DatePicker } from 'antd'

import './Report.css'
import { simpleReducer, sortByName } from '../../../helper'
import FilterTitle from './FilterTitle'
import { AppConfig } from '../../../AppConfig'
import { useOrgTreeQuery } from '../../../api/react-query/org'
import ReportList from '../../../components/Admin/Reports/ReportList'
import { useGetReportsQueue, useMutateDispatchReportToQueue } from '../../../api/react-query/reports'
import moment from 'moment-timezone'

const { Title } = Typography
const { Option } = Select
const { RangePicker } = DatePicker

const now = moment()
const initialStart = moment().subtract(6, 'days')
const selectSearchFilter = (input, option) => {
  return option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
}

export default function GivenVirtualCardReport () {
  const [form] = Form.useForm()
  const initialState = {
    start: initialStart,
    end: now,
    cities: [],
    selectedCities: [],
    currentLoadingReportId: null
  }
  const [state, setState] = useReducer(simpleReducer, initialState)

  const {
    isSuccess: isSuccessOrgTree,
    isError: isErrorOrgTree,
    data: dataOrgTree,
    isLoading: isLoadingOrgTree
  } = useOrgTreeQuery({
    withEmptyCity: true,
    withClosed: true,
    withOpened: true
  })

  const {
    isError: isErrorReportsQueue,
    data: dataReportsQueue,
    isLoading: isLoadingReportsQueue,
    refetch: refetchReportsQueue
  } = useGetReportsQueue(AppConfig.report.types.virtualCards)

  const {
    mutate: addVirtualCardReportToQueue,
    isError: isErrorAddVirtualCardReportToQueue,
    data: dataAddVirtualCardReportToQueue,
    isLoading: isLoadingAddVirtualCardReportToQueue
  } = useMutateDispatchReportToQueue()

  useEffect(() => {
    if (isSuccessOrgTree && dataOrgTree?.isSuccess) {
      const cities = sortByName(dataOrgTree?.cities)
      setState({ cities })
    }
    if (isErrorOrgTree || dataOrgTree?.errorMessage) {
      message.error(dataOrgTree?.errorMessage || dataOrgTree?.title || 'Ошибка доступа к данным')
    }
  }, [dataOrgTree, isSuccessOrgTree, isErrorOrgTree])

  useEffect(() => {
    if (isErrorAddVirtualCardReportToQueue || dataAddVirtualCardReportToQueue?.errorMessage) {
      message.error(dataAddVirtualCardReportToQueue?.errorMessage || 'Не удалось поставить отчет в очередь')
    }
  }, [isErrorAddVirtualCardReportToQueue, dataAddVirtualCardReportToQueue])

  useEffect(() => {
    if (isErrorReportsQueue || dataReportsQueue?.errorMessage) {
      message.error(dataReportsQueue?.errorMessage || 'Не удалось получить отчеты')
    }
  }, [isErrorReportsQueue, dataReportsQueue])

  const handleChangeCities = useCallback(
    (value, items) => {
      setState({
        selectedCities: items.map(e => e.key)
      })
    },
    [form, state]
  )

  const getReportQueryData = (variant) => ({
    cityIds: state.selectedCities.length ? state.selectedCities : undefined,
    minVerificationDate: moment(state.start)
      .set({ hour: 0, minute: 0, second: 0 })
      .format(AppConfig.formats.shortDateAndTimeApi),
    maxVerificationDate: moment(state.end)
      .set({ hour: 24, minute: 0, second: 0 })
      .format(AppConfig.formats.shortDateAndTimeApi),
    variant
  })

  const handleAddReportToQueue = (reportVersion) => {
    addVirtualCardReportToQueue({
      type: AppConfig.report.types.virtualCards,
      data: getReportQueryData(reportVersion)
    })
  }

  const datePickerDefaultValue = useMemo(() => [state.start, state.end], [])
  const handleChangeFilter = ([start, end]) => setState({ start, end })

  return (
    <>
      <Title level={3} className='title-management'>Отчет по выданным виртуальным картам</Title>
      <Form
        form={form}
      >

        <FilterTitle title='Период'>
          <Form.Item>
            <RangePicker
              defaultValue={datePickerDefaultValue}
              onChange={handleChangeFilter}
            />
          </Form.Item>
        </FilterTitle>

        <FilterTitle title='Город'>
          <Form.Item name='cities'>
            <Select
              loading={isLoadingOrgTree}
              onChange={handleChangeCities}
              size='middle'
              placeholder='Выберите город'
              showSearch
              optionFilterProp='children'
              filterOption={selectSearchFilter}
              allowClear
              mode='multiple'
            >
              {state.cities?.map(city => (
                <Option key={city.id} value={city.name}>
                  {city.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </FilterTitle>

        <Row>
          <Col className='mr-3'>
            <Button
              loading={isLoadingAddVirtualCardReportToQueue}
              type='primary'
              htmlType='button'
              onClick={() => handleAddReportToQueue('v2')}
            >
              Запросить отчет
            </Button>
          </Col>
        </Row>
      </Form>
      {
        dataReportsQueue?.items &&
          <>
            <Divider />
            <Row className='mt-3' gutter={15} align='middle'>
              <Col>
                <Title level={5}>Отчеты</Title>
              </Col>
              <Col>
                <Button onClick={() => refetchReportsQueue()}>Обновить</Button>
              </Col>
            </Row>
            <ReportList
              isLoadingReports={isLoadingReportsQueue}
              dataReports={dataReportsQueue?.items}
            />
          </>
      }
    </>
  )
}
