import React, { useEffect, useMemo, useState } from 'react'
import { DownOutlined } from '@ant-design/icons'
import { message, Tree, Spin, Typography } from 'antd'

import { useAllOrgCitiesQuery } from '../../../api/react-query/org'
import { ALL, twoMinutes } from '../../../Constants'
import { sortByName } from '../../../helper'

const { Text } = Typography

export default function CityTree ({
  height, // высота виртуального скрола дерева
  onSelect = () => {},
  externalData = {},
  switcherIcon = <DownOutlined />, // иконка разворачивания дерева
  checkable,
  disabled = false,
  checkedKeys = [],
  onCheck = () => {},
  selectable = false
}) {
  const [cityTree, setCityTree] = useState([])

  const {
    isSuccess,
    isError,
    data,
    isLoading
  } = useAllOrgCitiesQuery({
    staleTime: twoMinutes,
    enabled: !Object.keys(externalData || {})?.length
  })
  useEffect(() => {
    if (externalData?.cities) {
      setCityTree(sortByName(externalData.cities))
    }
  }, [externalData])

  useEffect(() => {
    if (isSuccess && !externalData?.cities) {
      setCityTree(sortByName(data?.result))
    }
  }, [data, externalData, isSuccess])
  /**
   * Errors from api requests
   */
  useEffect(() => {
    if (isError || data?.errorMessage) {
      message.error(data?.errorMessage || data?.title || 'Ошибка доступа к данным')
    }
  }, [isError, data])

  const treeData = useMemo(() => {
    const children = cityTree.map(item => ({
      title: item.name,
      key: item.id
    }))
    return checkable ? [
      {
        title: 'Выбрать все',
        key: ALL,
        children
      }
    ] : children
  }, [checkable, cityTree])

  return (
    <Spin spinning={isLoading} size='large'>
      {cityTree.length < 1
        ? <Text type='secondary'>Область видимости не задана</Text>
        : (
          <Tree
            showIcon
            height={height}
            checkable={checkable}
            disabled={disabled}
            onCheck={onCheck}
            checkedKeys={checkedKeys}
            switcherIcon={switcherIcon}
            onSelect={onSelect}
            treeData={treeData}
            selectable={selectable}
            defaultExpandedKeys={[ALL]}
            className='user-city-tree'
          />
        )}
    </Spin>
  )
}
