import React, { useCallback, useEffect, useRef, useState, useContext } from 'react'
// import { Document, Page, pdfjs } from 'react-pdf/dist/umd/entry.webpack'
import { Document, Page, pdfjs } from 'react-pdf'
import { makeFileUrl } from '../../../../helper'
import { Button, Modal, Row, Spin } from 'antd'
import Draggable from 'react-draggable'
import axios from 'axios'
import ScrollContainer from 'react-indiana-drag-scroll'
import { MinusOutlined, PlusOutlined, RotateLeftOutlined, RotateRightOutlined, ColumnWidthOutlined } from '@ant-design/icons'
import './ViewPdf.css'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import { ConfigContext } from '../../../../context/configContext'
import { getLogHeaders } from '../../../../api/react-query/api'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const rotateValues = [0, 90, 180, 270]

const PdfDocument = ({
  file,
  scale = 1,
  onClick = null,
  showPagination = false,
  showZoom = false,
  showRotate = false,
  showFlip = false,
  showSave = false,
  onSave = () => {},
  isLoading = false,
  onClose = () => {}
}) => {
  const [numPages, setNumPages] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [zoom, setZoom] = useState(scale)
  const [rotate, setRotate] = useState(rotateValues[0])
  const [flipped, setFlipped] = useState(false)

  useEffect(() => {
    setZoom(scale)
  }, [scale])

  const getNextAngle = useCallback(() => {
    let index = rotateValues.indexOf(rotate)
    return rotateValues[++index] || rotateValues[0]
  }, [rotate])

  const getPrevAngle = useCallback(() => {
    let index = rotateValues.indexOf(rotate)
    index--
    return rotateValues[index] ?? rotateValues[3]
  }, [rotate])

  const handleRotateRight = useCallback(() => {
    setRotate(getNextAngle())
  }, [getNextAngle, setRotate])

  const handleRotateLeft = useCallback(() => {
    setRotate(getPrevAngle())
  }, [getPrevAngle, setRotate])

  const handleFlip = () => {
    setFlipped(!flipped)
  }

  const handleSave = async () => {
    const res = await onSave({
      angle: rotate,
      flip: flipped
    })
    if (res.isSuccess) {
      setRotate(rotateValues[0])
      setFlipped(false)
      onClose()
    }
  }

  if (!file) {
    return null
  }

  return (
    <Document
      file={file}
      onLoadSuccess={({ numPages }) => setNumPages(numPages)}
      onLoadError={(e) => console.error(e)}
      onClick={() => onClick?.()}
      loading={<Spin spinning />}
    >
      {
        numPages > 1 && showPagination &&
          <Row align='middle' justify='center' className='mb-3 ml-2 mr-2'>
            Страница {currentPage} из {numPages}
            <Button
              className='mr-3 ml-3'
              disabled={currentPage === 1}
              onClick={() => setCurrentPage(currentPage - 1)}
            >Пред.
            </Button>
            <Button
              disabled={currentPage === numPages}
              onClick={() => setCurrentPage(currentPage + 1)}
            >След.
            </Button>
          </Row>
      }
      <div className='actions-buttons'>
        {
          showSave &&
            <Row align='middle' justify='center' className='mb-3 ml-2 mr-2'>
              <Button
                disabled={rotate === rotateValues[0]}
                loading={isLoading}
                onClick={handleSave}
              >Сохранить
              </Button>
            </Row>
        }
        {
          showFlip &&
            <Row align='middle' justify='center' className='mb-3 ml-2 mr-2'>
              Отразить
              <Button
                icon={<ColumnWidthOutlined />}
                className='mr-3 ml-3'
                onClick={handleFlip}
                type={flipped ? 'primary' : 'ghost'}
              />
            </Row>
        }
        {
          showRotate &&
            <Row align='middle' justify='center' className='mb-3 ml-2 mr-2'>
              Поворот
              <Button
                icon={<RotateLeftOutlined />}
                className='mr-3 ml-3'
                onClick={handleRotateLeft}
              />
              <Button
                icon={<RotateRightOutlined />}
                onClick={handleRotateRight}
              />
            </Row>
        }
        {
          showZoom &&
            <Row align='middle' justify='center' className='mb-3'>
              Масштаб
              <Button
                icon={<MinusOutlined />}
                className='mr-3 ml-3'
                disabled={zoom === scale}
                onClick={() => setZoom(zoom - 0.5)}
              />
              <Button
                icon={<PlusOutlined />}
                disabled={zoom === 4}
                onClick={() => setZoom(zoom + 0.5)}
              />
            </Row>
        }
      </div>
      {
        showZoom ? (
          <ScrollContainer className='scroll-container' hideScrollbars={false}>
            <Page
              scale={zoom}
              renderTextLayer={false}
              loading={<Spin spinning />}
              pageNumber={currentPage}
              // через этот параметр не вращать, для этого надо знать на сколько уже повернут текущий пдф
              // rotate={rotate}
              className={[
                ...(flipped ? ['flipped'] : []),
                ...(rotate !== undefined && rotate !== null ? [`rotated-${rotate}`] : [])
              ]}
            />
          </ScrollContainer>
        ) : (
          <Page
            renderTextLayer={false}
            loading={<Spin spinning />}
            pageNumber={currentPage}
            height={450}
          />
        )
      }
    </Document>
  )
}

function ViewPdf ({ fileId, title, onSave, isLoading }) {
  const { isAdmin, isCoordinator } = useContext(ConfigContext)
  const [isPreviewOpen, setIsPreviewOpen] = useState(false)
  const [file, setFile] = useState(null)
  const [fileModal, setFileModal] = useState(null)
  const [disabled, setDisabled] = useState(false)

  const dragRef = useRef(null)

  useEffect(() => {
    if (fileId && !file) {
      axios.get(makeFileUrl(fileId), {
        responseType: 'blob'
      }).then(response => {
        response.arrayBuffer().then(data => setFile({ data }))
      })
    }
  }, [fileId])

  const handleClose = () => {
    setIsPreviewOpen(false)
  }

  const handleOpen = () => {
    setIsPreviewOpen(true)
    axios.get(makeFileUrl(fileId), {
      responseType: 'blob',
      headers: getLogHeaders('Partner document viewer')
    }).then(response => {
      response.arrayBuffer().then(data => setFileModal({ data }))
    })
  }

  return (
    <>
      <PdfDocument file={file} onClick={handleOpen} />
      <Modal
        title={
          <div
            style={{
              width: '100%',
              cursor: 'move'
            }}
            onMouseOver={() => {
              if (disabled) {
                setDisabled(false)
              }
            }}
            onMouseOut={() => {
              setDisabled(true)
            }}
          >
            {title}
          </div>
        }
        modalRender={(modal) => (
          <Draggable
            disabled={disabled}
            bounds={{ top: -50 }}
          >
            <div ref={dragRef}>{modal}</div>
          </Draggable>
        )}
        className='pdf-preview'
        visible={isPreviewOpen}
        onCancel={handleClose}
        footer={false}
        width='60%'
        destroyOnClose
      >
        <PdfDocument
          file={fileModal}
          showPagination
          showZoom
          showRotate
          showSave={Boolean(isAdmin || isCoordinator)}
          isLoading={isLoading}
          onSave={onSave}
          onClose={() => {
            handleClose()
            setFile(null)
          }}
        />
      </Modal>
    </>
  )
}
export default ViewPdf
